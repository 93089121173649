import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Subscription, forkJoin } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { BaseClass } from 'src/app/classes/base_class';
import { RealClub, RealClubResponse } from 'src/app/classes/real_club';
import { RealPlayer, RealPlayerResponse } from 'src/app/classes/real_player';
import { User, UserResponse } from 'src/app/classes/user';
import { AbstractService } from 'src/app/services/abstract.service';
import { AlertboxComponent } from '../alertbox/alertbox.component';
import { Booking, Goal, RealMatch, RealMatchResponse, Score, Substitution } from 'src/app/classes/real_match';
import { RealClubService } from 'src/app/services/real-club.service';
import { RealPlayerService } from 'src/app/services/real-player.service';
import { TacticmoduleService } from 'src/app/services/tacticmodule.service';
import { TacticModule, TacticModuleResponse } from 'src/app/classes/tactic_module';
import { RealCompetitionService } from 'src/app/services/real-competition.service';
import { RealCompetition } from 'src/app/classes/real_competition';
import { FilterRealClub } from 'src/app/classes/filterRealClub';

@Component({
  selector: 'app-single-realmatch',
  templateUrl: './single-realmatch.component.html',
  styleUrls: ['./single-realmatch.component.scss']
})
export class SingleRealmatchComponent implements OnInit {
  busy: Subscription;
  faPlus = faSave;
  rels: Array<any>;
  singleResponse: UserResponse | RealClubResponse | RealPlayerResponse;
  cols: any;
  varCustomClass = 'accordionCustomStyle';

  @Input() id: number;
  @Input() single: RealMatch;
  @Input() componentService: AbstractService;
  @Input() routePart: string;
  @Output() pathListEvent = new EventEmitter<any[]>();
  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;
  rplayer1: RealPlayer[];
  rplayer2: RealPlayer[];
  tmp_realclub1_lineup: any[];
  tmp_realclub2_lineup: any[];

  homeClub = "";
  visitorClub = "";
  tacticModules: TacticModule[];
  matchDate: Date;
  competitions: RealCompetition[];
  filterRealClub: FilterRealClub;
  realClubList: RealClub[];

  constructor(private router: Router, public realclubService: RealClubService, public realPlayerService: RealPlayerService, public tacticModule: TacticmoduleService, public realCompetitionService: RealCompetitionService) { }

  ngOnInit(): void {
    this.tacticModule.list2().subscribe(
      (data: TacticModuleResponse) => {
        this.tacticModules = data.payload;
      }
    );
    if (this.id > 0) {
      this.getSingle(this.id);
    } else {
      this.single.real_competition_id = -1;
      this.busy = this.realCompetitionService.list2().subscribe((competitions) => {
        const ccmp: RealCompetition[] = competitions.payload.filter(c => c.active == 1);
        this.competitions = ccmp.sort((a, b) => a.description > b.description ? 1 : -1);
      });
    }
  }

  selectCompetition($event) {
    let filter: Array<any> | null = null;
    filter = [];
    filter['realcompetition_id'] = $event.target.value;
    this.busy = this.realclubService.list2(filter).subscribe(rclubs => {
      this.realClubList = rclubs.payload.sort((a, b) => a.common_mame > b.common_mame ? 1 : -1);
    });
  }

  navigateTo() {
    this.router.navigate([`/${this.routePart}/${this.id}/table`]);
  }

  getControlType(control_name: string): string {
    if (control_name in this.single.controls) {
      return this.single.controls[control_name];
    }
    return '';
  }

  toArray(obj: any): any[] {
    Object.keys(obj).map(key => obj[key])
    return obj;
  }

  removePlayer(obj: RealPlayer, lineup) {
    // this.single[lineup].splice(obj, 1);
    this.single[lineup] = this.single[lineup].filter(item => item['id'] !== obj.id);

    const xx: RealPlayer = this.rplayer1.find((item) => item.id == obj.id);
    const yy: RealPlayer = this.rplayer2.find((item) => item.id == obj.id);
    if (xx) {
      xx.ctrl_convocato = false;
    }
    if (yy) {
      yy.ctrl_convocato = false;
    }
  }

  addPlayer(obj: RealPlayer, lineup, tacticmodule_id) {
    const roleToAdd = obj.ruolo;
    const checkRole = tacticmodule_id ? this.checkFormazioneModulo(lineup, tacticmodule_id, roleToAdd) : true;
    if (checkRole == false) {
      const provaAlert: Alert = new Alert();
      provaAlert.msg = 'Troppi giocatori per il ruolo ' + roleToAdd;
      provaAlert.timeout = 5000;
      provaAlert.type = 'danger';
      this.alertbox1.alerts = [];
      this.alertbox1.alerts.push(provaAlert);
      return false;
    } else {
      obj.ctrl_convocato = true;
      const rp = {
        'shirtNumber': obj['shirtNumber'],
        'calciatore': obj['calciatore'],
        'ruolo': obj['ruolo'],
      }
      if (!this.single[lineup]) {
        this.single[lineup] = Array();
      }
      this.single[lineup].unshift(obj);
      this.single[lineup] = this.orderByBasic(this.single[lineup]);
    }
  }

  /**
   *
   * @param payload dato in ingresso
   * @param obj dato in uscita
   * @returns obj
   */
  objCopy(payload, obj) {
    obj.ctrl_convocato = false;
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(this.single, key)) {
        obj[key] = payload[key];
      }
    }
    return obj;
  }

  isInLineUp(players: RealPlayer[], realplayer_id: number): Boolean {
    if (!players) return false;
    for (let item of players) {
      if (item.id == realplayer_id) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * move on-top in sequenza
   *  A, C, D, P
   *  1, 2, 3, 4
   * @param ruolo
   * @returns
   */
  ruolo2order(ruolo) {
    switch (ruolo) {
      case 'A':
        return 1;
      case 'C':
        return 2;
      case 'D':
        return 3;
      case 'P':
        return 4;
      default:
        return 0;
    }
  }

  orderByBasic(a: RealPlayer[]) {
    return a.sort((a: RealPlayer, b: RealPlayer) => {
      const r1 = this.ruolo2order(a.ruolo);
      const r2 = this.ruolo2order(b.ruolo);
      return (r1 > r2 ? -1 : 1);
    });
  }

  checkFormazioneModulo(lineup: string, tacticmodule_id: number, role: string, saving = 0): boolean {
    const isLineup = lineup.includes('lineup');
    const modulo = this.tacticModules.find(x => x.id == tacticmodule_id);
    const qtaA = modulo.ea + modulo.pc + modulo.t + modulo.sp + saving;
    const qtaC = modulo.cc + modulo.cf + saving;
    const qtaD = modulo.dc + modulo.dl + saving;
    const qtaP = 1 + saving;
    let lineupA = 0;
    let lineupC = 0;
    let lineupD = 0;
    let lineupP = 0;
    for (const rpl of this.single[lineup]) {
      if (rpl.ruolo == 'A') lineupA++;
      if (rpl.ruolo == 'C') lineupC++;
      if (rpl.ruolo == 'D') lineupD++;
      if (rpl.ruolo == 'P') lineupP++;
    }
    switch (role) {
      case 'A':
        if (lineupA < qtaA) { return true; }
        break;

      case 'C':
        if (lineupC < qtaC) { return true; }
        break;

      case 'D':
        if (lineupD < qtaD) { return true; }
        break;

      case 'P':
        if (lineupP < qtaP) { return true; }
        break;

      default:
        return false;
    }
    return false;
  }

  parseISOString(s) {
    const b = s.split(/\D+/);

    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
  }

  getSingle(id: number) {
    const isNew = id == 0 ? true : false;
    if (id == 0) id = 1;
    this.busy = this.componentService.get(id).subscribe(
      (data: RealMatchResponse) => {
        if (!isNew) {
          // this.single = data.payload;
          for (const key in data.payload) {
            if (Object.prototype.hasOwnProperty.call(this.single, key)) {
              this.single[key] = data.payload[key];
            }
          }
        }
        this.matchDate = this.parseISOString(this.single['utcDate']);

        // this.cols = data.admin_info.cols.filter((c) => c.indexOf('.') == -1); // rimuovo i campi fk
        let filter: Array<any> | null = null;
        filter = [];

        const $rclub1 = this.realclubService.get(this.single['realclub1_id']);
        filter['realclub_id'] = this.single['realclub1_id'];
        const $rplayer1 = this.realPlayerService.list2(filter);

        const $rclub2 = this.realclubService.get(this.single['realclub2_id']);
        filter['realclub_id'] = this.single['realclub2_id'];
        const $rplayer2 = this.realPlayerService.list2(filter);

        this.single.realclub1_lineup = this.orderByBasic(this.single.realclub1_lineup);
        this.single.realclub2_lineup = this.orderByBasic(this.single.realclub2_lineup);

        this.busy = forkJoin([$rclub1, $rclub2, $rplayer1, $rplayer2]).subscribe(([rclub1, rclub2, rplayer1, rplayer2]) => {
          this.pathListEvent.emit([rclub1.payload.description + ' - ' + rclub2.payload.description, `${this.routePart}/${this.single.id}`]);
          for (let rp1 of rplayer1.payload) {
            rp1.ctrl_convocato = false;
            if (this.isInLineUp(this.single.realclub1_lineup, rp1.id)) {
              rp1.ctrl_convocato = true;
            }
            if (this.isInLineUp(this.single.realclub1_bench, rp1.id)) {
              rp1.ctrl_convocato = true;
            }

            let x: RealPlayer = new RealPlayer();
            x = this.objCopy(rp1, x)
          }

          rplayer1.payload = this.orderByBasic(rplayer1.payload);
          rplayer2.payload = this.orderByBasic(rplayer2.payload);

          for (let rp2 of rplayer2.payload) {
            rp2.ctrl_convocato = false;
            if (this.isInLineUp(this.single?.realclub2_lineup, rp2.id)) {
              rp2.ctrl_convocato = true;
            }
            if (this.isInLineUp(this.single.realclub2_bench, rp2.id)) {
              rp2.ctrl_convocato = true;
            }

            let x: RealPlayer = new RealPlayer();
            x = this.objCopy(rp2, x)
          }

          this.rplayer1 = rplayer1.payload;
          this.rplayer2 = rplayer2.payload;
          this.homeClub = rclub1.payload.description;
          this.visitorClub = rclub2.payload.description;

        });
      },
      (err) => {
        console.log('ERRORE: ' + JSON.stringify(err.error.description));
      }
    );


  }

  getRels(rel: string) {
    // this.httpdata.get('/' + rel).subscribe(
    //   (data) => {
    //     this.rels.push({ class_name: rel, list: data });
    //   },
    //   (err) => {
    //     console.error(err);
    //     alert('ERRORE: ' + JSON.stringify(err.error.description));
    //   }
    // );
  }

  getRelated(rel: string) {
    // return this.rels.find((r) => {
    //   return r.class_name == rel;
    // }).list;
  }

  saveItem() {
    if (this.id > 0) {
      const rolesToCheck = ['A', 'C', 'D'];
      for (const roleToCheck of rolesToCheck) {
        // console.log(this.checkFormazioneModulo('realclub1_lineup', this.single['tactic_module1_id'], roleToCheck));
        const checkRole1 = this.single['tactic_module1_id'] ? this.checkFormazioneModulo('realclub1_lineup', this.single['tactic_module1_id'], roleToCheck, 1) : true;
        const checkRole2 = this.single['tactic_module2_id'] ? this.checkFormazioneModulo('realclub2_lineup', this.single['tactic_module2_id'], roleToCheck, 1) : true;
        if (checkRole1 == false || checkRole2 == false) {
          const sndAlert: Alert = new Alert();
          const team = (checkRole1 == false ? this.homeClub : this.visitorClub);
          sndAlert.msg = 'Troppi giocatori per il ruolo ' + roleToCheck + ' del team ' + team;
          sndAlert.timeout = 5000;
          sndAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(sndAlert);
          return false;
        }
      }
    } else {

      let _score: Score = { away: "", awayTeam: "0", home: "", homeTeam: "0" };
      this.single.score_fullTime = _score;
    }

    this.single['utcDate'] = this.matchDate;
    const payload = BaseClass.cleanup(this.single);
    // const payload = this.single;
    this.single.description = '';
    if (this.single.id == 0) {
      this.busy = this.componentService.create(payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'normal';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
          this.router.navigate([`/${this.routePart}/list`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    } else {
      this.busy = this.componentService.update(this.single.id, payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "Salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'primary';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert);
          // this.router.navigate([`/${this.routePart}/${this.single.id}`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    }
  }

  parse(o) {
    return JSON.parse(o);
  }


}

<div [ngBusy]="{busy: busy, message: 'Loading...', backdrop: false, delay: 0, minDuration: 600}"></div>
<div class="row d-flex justify-content-between">
    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
    </div>
    <div class="input-group input-group-sm mb-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <fa-icon [icon]="faPlus"></fa-icon>
                Salva
            </button>
        </div>
    </div>
</div>

<div class="pr-3 pl-3 mb-5">
    <div class="table-responsive">
        <table class="table table-hover table-sm table-real-match little-table" id="tabInfo">
            <thead class="thead-default">
                <tr>
                    <th>{{homeClub}}</th>
                    <th>Voto</th>
                    <!-- <th>Fanta Voto</th> -->
                    <th>GOAL FATTI</th>
                    <th>Assist</th>
                    <th>GOAL subiti</th>
                    <th>Clean Sheet</th>
                    <th>RIG. PAR</th>
                    <th>RIG. SBA</th>
                    <th>AUTO GOAL</th>
                    <th><i class="fas fa-address-book yellow-card fa-fw"></i> GIALLI</th>
                    <th><i class="fas fa-address-book red-card fa-fw"></i> ROSSI</th>
                    <!-- <th>Titolare</th>-->
                    <th>Sostituzione (min)</th> 
                    <th>%T</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rplayer1 of homeClubScardList1">
                    <td>
                        <span class="shirt-number-tabellino" class="{{rplayer1['ruolo']}}">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                            </svg>
                            <span class="number">{{rplayer1.shirt_number}}</span>
                        </span>
                        <span class="player-name">{{rplayer1.realplayer_description}}</span>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.voto" step=0.5
                            [id]="'voto' + rplayer1.realplayer_id">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.fonte_voti" step=0.5
                            [id]="'fonte_voti' + rplayer1.fonte_voti">
                    </td> -->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.gol_fatti"
                            [id]="'gol_fatti' + rplayer1.gol_fatti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.assist"
                            [id]="'assist' + rplayer1.assist">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.gol_subiti"
                            [id]="'gol_subiti' + rplayer1.gol_subiti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.clean_shit"
                            [id]="'clean_shit' + rplayer1.clean_shit">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.rigore_parato"
                            [id]="'rigore_parato' + rplayer1.rigore_parato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number"
                            [(ngModel)]="rplayer1.rigore_sbagliato"
                            [id]="'rigore_sbagliato' + rplayer1.rigore_sbagliato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.autogol"
                            [id]="'autogol' + rplayer1.autogol">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.ammonizioni"
                            [id]="'ammonizioni' + rplayer1.ammonizioni">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.espulsione"
                            [id]="'espulsione' + rplayer1.espulsione">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.titolare"
                            [id]="'titolare' + rplayer1.titolare">
                    </td>-->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.da_panchina" [id]="'da_panchina' + rplayer1.da_panchina">
                    </td> 
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.titolare"
                            [id]="'T' + rplayer1.realplayer_id" >
                    </td>
                </tr>
                <tr>
                    <td class="td-bench" colspan="14">Panchina</td>
                </tr>
                
                <tr class="fake-thead">
                    <th></th>
                    <th>Voto</th>
                    <!-- <th>Fanta Voto</th> -->
                    <th>GOAL FATTI</th>
                    <th>Assist</th>
                    <th>GOAL subiti</th>
                    <th>Clean Sheet</th>
                    <th>RIG. PAR</th>
                    <th>RIG. SBA</th>
                    <th>AUTO GOAL</th>
                    <th><i class="fas fa-address-book yellow-card fa-fw"></i> GIALLI</th>
                    <th><i class="fas fa-address-book red-card fa-fw"></i> ROSSI</th>
                    <!-- <th>Titolare</th>-->
                    <th>Sostituzione (min)</th> 
                    <th>%T</th>
                </tr>

                <tr *ngFor="let rplayer1a of homeClubScardList1a">
                    <td>
                        <span class="shirt-number-tabellino" class="{{rplayer1a['ruolo']}}">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                            </svg>
                            <span class="number">{{rplayer1a.shirt_number}}</span> 
                            
                        </span>
                        <span class="player-name">{{rplayer1a.realplayer_description}}</span>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.voto" step=0.5
                            [id]="'voto' + rplayer1a.realplayer_id">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.gol_fatti"
                            [id]="'gol_fatti' + rplayer1a.gol_fatti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.assist"
                            [id]="'assist' + rplayer1a.assist">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.gol_subiti"
                            [id]="'gol_subiti' + rplayer1a.gol_subiti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.clean_shit"
                            [id]="'clean_shit' + rplayer1a.clean_shit">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.rigore_parato"
                            [id]="'rigore_parato' + rplayer1a.rigore_parato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number"
                            [(ngModel)]="rplayer1a.rigore_sbagliato"
                            [id]="'rigore_sbagliato' + rplayer1a.rigore_sbagliato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.autogol"
                            [id]="'autogol' + rplayer1a.autogol">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.ammonizioni"
                            [id]="'ammonizioni' + rplayer1a.ammonizioni">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.espulsione"
                            [id]="'espulsione' + rplayer1a.espulsione">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1.titolare"
                            [id]="'titolare' + rplayer1.titolare">
                    </td>-->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.da_panchina"
                            [id]="'da_panchina' + rplayer1a.da_panchina">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer1a.titolare"
                            [id]="'T' + rplayer1a.realplayer_id">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="pr-3 pl-3 mb-5">
    <div class="table-responsive">
        <table class="table table-hover table-sm table-real-match little-table" id="tabInfo">
            <thead class="thead-default">
                <tr>
                    <th>{{visitorClub}}</th>
                    <th>Voto</th>
                    <th>GOAL FATTI</th>
                    <th>Assist</th>
                    <th>GOAL subiti</th>
                    <th>Clean Sheet</th>
                    <th>RIG. PAR</th>
                    <th>RIG. SBA</th>
                    <th>AUTO GOAL</th>
                    <th><i class="fas fa-address-book yellow-card fa-fw"></i> GIALLI</th>
                    <th><i class="fas fa-address-book red-card fa-fw"></i> ROSSI</th>
                    <!-- <th>Titolare</th>-->
                    <th>Sostituzione (min)</th> 
                    <th>%T</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rplayer2 of homeClubScardList2">
                    <td>
                        <span class="shirt-number-tabellino" class="{{rplayer2['ruolo']}}">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                            </svg>
                            <span class="number">{{rplayer2.shirt_number}}</span>
                            
                        </span>
                        <span class="player-name">{{rplayer2.realplayer_description}}</span>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.voto" step=0.5
                            [id]="'voto' + rplayer2.realplayer_id">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.fonte_voti" step=0.1
                            [id]="'fonte_voti' + rplayer2.fonte_voti">
                    </td> -->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.gol_fatti"
                            [id]="'gol_fatti' + rplayer2.gol_fatti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.assist"
                            [id]="'assist' + rplayer2.assist">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.gol_subiti"
                            [id]="'gol_subiti' + rplayer2.gol_subiti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.clean_shit"
                            [id]="'clean_shit' + rplayer2.clean_shit">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.rigore_parato"
                            [id]="'rigore_parato' + rplayer2.rigore_parato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number"
                            [(ngModel)]="rplayer2.rigore_sbagliato"
                            [id]="'rigore_sbagliato' + rplayer2.rigore_sbagliato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.autogol"
                            [id]="'autogol' + rplayer2.autogol">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.ammonizioni"
                            [id]="'ammonizioni' + rplayer2.ammonizioni">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.espulsione"
                            [id]="'espulsione' + rplayer2.espulsione">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.titolare"
                            [id]="'titolare' + rplayer2.titolare">
                    </td>-->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.da_panchina" [id]="'da_panchina' + rplayer2.da_panchina">
                    </td> 
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2.titolare"
                            [id]="'T' + rplayer2.realplayer_id">
                    </td>
                </tr>

                <tr> <td  class="td-bench" colspan="14">Panchina</td> </tr>

                <tr class="fake-thead">
                    <th></th>
                    <th>Voto</th>
                    <!-- <th>Fanta Voto</th> -->
                    <th>GOAL FATTI</th>
                    <th>Assist</th>
                    <th>GOAL subiti</th>
                    <th>Clean Sheet</th>
                    <th>RIG. PAR</th>
                    <th>RIG. SBA</th>
                    <th>AUTO GOAL</th>
                    <th><i class="fas fa-address-book yellow-card fa-fw"></i> GIALLI</th>
                    <th><i class="fas fa-address-book red-card fa-fw"></i> ROSSI</th>
                    <!-- <th>Titolare</th>-->
                    <th>Sostituzione (min)</th> 
                    <th>%T</th>
                </tr>

                <tr *ngFor="let rplayer2a of homeClubScardList2a">
                    <td>
                        <span class="shirt-number-tabellino" class="{{rplayer2a['ruolo']}}">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                            </svg>
                            <span class="number">{{rplayer2a.shirt_number}}</span> 
                            
                        </span>
                        <span class="player-name">{{rplayer2a.realplayer_description}}</span>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.voto" step=0.5
                            [id]="'voto' + rplayer2a.realplayer_id">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.fonte_voti" step=0.1
                            [id]="'fonte_voti' + rplayer2a.fonte_voti">
                    </td> -->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.gol_fatti"
                            [id]="'gol_fatti' + rplayer2a.gol_fatti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.assist"
                            [id]="'assist' + rplayer2a.assist">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.gol_subiti"
                            [id]="'gol_subiti' + rplayer2a.gol_subiti">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.clean_shit"
                            [id]="'clean_shit' + rplayer2a.clean_shit">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.rigore_parato"
                            [id]="'rigore_parato' + rplayer2a.rigore_parato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number"
                            [(ngModel)]="rplayer2a.rigore_sbagliato"
                            [id]="'rigore_sbagliato' + rplayer2a.rigore_sbagliato">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.autogol"
                            [id]="'autogol' + rplayer2a.autogol">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.ammonizioni"
                            [id]="'ammonizioni' + rplayer2a.ammonizioni">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.espulsione"
                            [id]="'espulsione' + rplayer2a.espulsione">
                    </td>
                    <!-- <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.titolare"
                            [id]="'titolare' + rplayer2a.titolare">
                    </td>-->
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.da_panchina"
                            [id]="'da_panchina' + rplayer2a.da_panchina">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="rplayer2a.titolare"
                            [id]="'T' + rplayer2a.realplayer_id">
                    </td> 
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="d-flex justify-content-between">
    <div class="input-group input-group-sm  mt-2 item d-flex justify-content-start">
    </div>
    <div class="input-group input-group-sm mt-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <fa-icon [icon]="faPlus"></fa-icon>
                Salva
            </button>
        </div>
    </div>
</div>

<app-alertbox #alertbox1 [alerts]=""></app-alertbox>